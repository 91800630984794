import useGetText from '../../hooks/useGetText/useGetText'
import { handleRedirect } from '../../utils/handleNavigationPath'
import defaultLanguageJson from '../../assets/locale/en_US.json'
import { NotificationType, NotificationID } from '../../utils/common-utils'
interface Notification {
  title: string
  description: string
  showCloseButton: boolean
  notificationID: string
  notificationType: string
  SecondCallbackFunction?: () => void // Optional
}
const GetAllPrearrivalNotification = (
  mfeName: string,
  printer: any,
  enableBuyerRemorseCancellation: boolean | null,
  navigation: any,
  subscriptionId: string | null,
  pendingData: any
): Notification | {} => {
  const entityState = printer?.state

  const getText = useGetText('preArrival')

  if (entityState !== 'pending') {
    return null
  }

  if (!enableBuyerRemorseCancellation) {
    const notification = getPrearrivalNotification(mfeName, getText)
    return notification || null // Return the notification or null directly
  }

  if (enableBuyerRemorseCancellation) {
    const preShipContent = pendingData?.contents?.find(
      (content) => content?.type === 'cancellation'
    )

    const isPreShip = !!preShipContent

    const notification = isPreShip
      ? getBuyerRemorseCancellationStatusNotification(
          mfeName,
          getText,
          subscriptionId,
          navigation
        )
      : getPrearrivalNotification(mfeName, getText)

    return notification || null
  }

  function getBuyerRemorseCancellationStatusNotification(
    mfeName,
    getText,
    subscriptionId,
    navigation
  ) {
    switch (mfeName) {
      case 'overview':
      case 'updatePlan':
      case 'Trackshipments':
      case 'Subscriptions':
        return {
          title: getText('preShip.afterCancel.title', {
            defaultValue:
              defaultLanguageJson.preArrival.preShip.afterCancel.title
          }),

          description: getText('preShip.afterCancel.description', {
            defaultValue:
              defaultLanguageJson.preArrival.preShip.afterCancel.description
          }),

          showCloseButton: false,

          notificationID: NotificationID.PRESHIPPED_AFTERCANCEL,

          notificationType: NotificationType.CUSTOM,

          SecondCallbackFunction: () =>
            handleRedirect(subscriptionId, navigation)
        }

      default:
        return null
    }
  }

  function getPrearrivalNotification(mfeName, getText) {
    switch (mfeName) {
      case 'overview':
        return {
          title: getText('overview.title', {
            defaultValue: defaultLanguageJson.preArrival.overview.title
          }),

          description: getText('overview.description', {
            defaultValue: defaultLanguageJson.preArrival.overview.description
          }),

          showCloseButton: true,

          notificationID: NotificationID.OVERVIEW_PREARRIVAL,

          notificationType: NotificationType.POSITIVE
        }

      case 'updatePlan':
        return {
          title: getText('updatePlan.title', {
            defaultValue: defaultLanguageJson.preArrival.updatePlan.title
          }),
          description: '',
          showCloseButton: false,
          notificationID: NotificationID.UPDATEPLAN_PREARRIVAL,
          notificationType: NotificationType.CUSTOM
        }

      case 'Trackshipments':
        return {
          title: getText('Trackshipments.title', {
            defaultValue: defaultLanguageJson.preArrival.Trackshipments.title
          }),
          description: getText('Trackshipments.description', {
            defaultValue:
              defaultLanguageJson.preArrival.Trackshipments.description
          }),
          showCloseButton: false,
          notificationID: NotificationID.TRACKSHIPMENTS_PREARRIVAL,
          notificationType: NotificationType.CUSTOM
        }

      case 'Subscriptions':
        return {
          title: getText('Subscriptions.title', {
            defaultValue: defaultLanguageJson.preArrival.Subscriptions.title
          }),

          description: getText('Subscriptions.description', {
            defaultValue:
              defaultLanguageJson.preArrival.Subscriptions.description
          }),

          showCloseButton: true,

          notificationID: NotificationID.SUBSCRIPTION_PREARRIVAL,

          notificationType: NotificationType.POSITIVE
        }

      default:
        return null
    }
  }
}
export default GetAllPrearrivalNotification
